const SOAL_URL = process.env.REACT_APP_SOAL_API_URL ?? "";
const apiToken = process.env.REACT_APP_SOAL_API_TOKEN ?? "";

const fetchSOALAPI = (path = "/", cache, method = "GET", query = "") => {
  // console.log('DEBUG: fetch SOAL API with ' + SOAL_URL + path)

  if (cache && cache["[SOAL]" + path])
    return new Promise((resolve) => {
      resolve(cache["[SOAL]" + path]);
    });

  const doFetch = function (path, method) {
    switch (method) {
      case "GET":
        let url = SOAL_URL + path + `?token=${apiToken}`;
        if (query.length > 0) url += query;
        return fetch(url);
      case "POST":
        const auth = new window.FormData();
        auth.set("token", apiToken);
        return fetch(SOAL_URL + path, { method: method, body: auth });
      default:
        break;
    }
  };

  return doFetch(path, method)
    .then((response) => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      }
      return response.json();
    })
    .then((json) => {
      if (cache) cache["[SOAL]" + path] = json;
      return json;
    })
    .catch((e) => {
      console.log("Couldn´t fetch data => " + e.message);
      return {};
    });
};

export default fetchSOALAPI;

import React from 'react'

import loader from '../assets/soal_icons-load.gif'

const Loader = () => {
  return (
    <div className='page is-loader'>
      <div className='page-loader-wrapper'>
        <img src={loader} alt='logo gif' />
      </div>
    </div>
  )
}

export default Loader

const KIRBY_URL = process.env.REACT_APP_KIRBY_URL ?? "";
const headers = {
  Authorization:
    "Basic " +
    Buffer.from(
      `${process.env.REACT_APP_KIRBY_USER}:${process.env.REACT_APP_KIRBY_PASSWORD}`
    ).toString("base64"),
  "Content-Type": "application/json",
};
const apiPath = "/api/custom";

const fetchKirbyAPI = async (
  path = "/",
  method = "GET",
  cache,
  withAPIPath = true,
  body = ""
) => {
  const cachedData = cache && cache["[KIRBY]" + path];
  if (cachedData) return cachedData;

  let url = withAPIPath ? KIRBY_URL + apiPath + path : KIRBY_URL + path;

  const requestOptions = {
    method: method,
    headers: headers,
    ...(body && method !== "GET" ? { body: body } : {}),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (response.status >= 400) {
      throw new Error("Bad response from server");
    }

    const json = await response.json();

    if (cache) {
      cache["[KIRBY]" + path] = json;
    }

    return json;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export default fetchKirbyAPI;

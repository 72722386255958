import React, { lazy, useState, useEffect } from "react";
import { withConsumer } from "./helpers/CacheContext";
import Loader from "./components/Loader";

const PageListItem = lazy(() =>
  import("./components/PageListItem/PageListItem")
);
const PageContent = lazy(() => import("./components/PageContent"));
const PageList = lazy(() => import("./components/PageList"));

const Routing = (props) => {
  const { fetchData, location } = props;
  const [content, setContent] = useState();

  useEffect(() => {
    setContent(null);
  }, [location.pathname]);

  useEffect(() => {
    const path = location.pathname.substring(1);
    const parents = path.split("/");
    const slug = parents.pop();

    if (parents.length > 0) {
      // load parent page to check if it´s a list
      fetchData(parents.join("/")).then((data) => {
        if (data.pageType === "list") {
          // then check if requested page is not a subpage
          for (let i = 0; i < data.subpages.length; i++) {
            if (data.subpages[i].id === path) {
              fetchData(path.toLowerCase()).then((data) =>
                setContent((state) => data)
              );
              return;
            }
          }

          // load requested listitem from parent cache object
          if (data.items) {
            let items = Object.values(data.items);
            for (let i = 0; i < items.length; i++) {
              if (
                (items[i].hasOwnProperty("permalink") &&
                  items[i].permalink === slug) ||
                (items[i].hasOwnProperty("id") &&
                  items[i].id != null &&
                  items[i].id.toString() === slug) ||
                (items[i].hasOwnProperty("seminarnummer") &&
                  items[i].seminarnummer != null &&
                  items[i].seminarnummer.toLowerCase() === slug.toLowerCase())
              ) {
                setContent({
                  item: items[i],
                  pageType: "listitem",
                  title: data.title,
                  path: data.path,
                  widgets: data.widgets,
                  image: data.image,
                  listContent: data.listtexts,
                });
                return;
              }
            }
          }
        } else {
          // load requested page
          fetchData(path.toLowerCase()).then((data) =>
            setContent((state) => data)
          );
        }
      });
    } else {
      // load requested page
      fetchData(path.toLowerCase()).then((data) => setContent((state) => data));
    }
  }, [fetchData, location.pathname]);

  if (content) {
    switch (content.pageType) {
      case "list":
        return <PageList content={content} location={location} />;
      case "listitem":
        return (
          <PageListItem
            content={content}
            location={location}
            widgets={content.widgets}
            image={content.image}
            listContent={content.listContent}
          />
        );
      case "content":
        return <PageContent content={content} location={location} />;
      default:
        return <Loader />;
    }
  }
  return <Loader />;
};

export default withConsumer(Routing);

import React from "react";
import fetchKirbyAPI from "./fetchKirbyAPI";
import fetchSOALAPI from "./fetchSOALAPI";

const CacheContext = React.createContext();

let cache = {};

function CacheProvider(props) {
  const fetchAnsprechpartner = async (name) => {
    return fetchSOALAPI("/team", cache).then((data) => {
      let departments = Object.keys(data);

      for (let i = 0; i < departments.length; i++) {
        const department = departments[i];
        let teamMemberIds = Object.keys(data[department]);

        for (let j = 0; j < teamMemberIds.length; j++) {
          const teamMemberId = teamMemberIds[j];

          let teamMemberName =
            data[department][teamMemberId].vorname +
            data[department][teamMemberId].nachname;
          if (teamMemberName.replaceAll(" ", "") === name.replaceAll(" ", "")) {
            return data[department][teamMemberId];
          }
        }
      }
    });
  };

  const fetchData = async (context) => {
    const isArray = Array.isArray(context);
    const key = "/" + (isArray ? context.join("/") : context);
    let response;

    // load from cache if available
    if (cache && cache[key]) {
      if (!(cache[key].pageType === "list" && cache[key].items === undefined)) {
        return new Promise((resolve) => {
          resolve(cache[key]);
        });
      }
    }

    return new Promise(async (resolve, reject) => {
      response = await fetchKirbyAPI(key, "GET", cache);
      if (!response) return;

      if (["list"].includes(response.pageType) && response.resource) {
        response.items = await fetchSOALAPI(response.resource, cache);
      }

      // rearrange the items (special cases)
      if (response.resource === "/team") {
        let teams = response.items;
        let teamKeys = Object.keys(teams).sort();
        let result = [];
        for (let i = 0; i < teamKeys.length; i++) {
          let teamMemberKeys = Object.keys(teams[teamKeys[i]]);
          for (let j = 0; j < teamMemberKeys.length; j++) {
            let teamMember = teams[teamKeys[i]][teamMemberKeys[j]];
            let teamKey = teamMember.vorname + teamMember.nachname;
            result[teamKey] = teamMember;
          }
        }
        response.items = result.sort();
      }
      cache = { ...cache, [key]: response };
      resolve(response);
    });
  };

  return (
    <CacheContext.Provider value={{ fetchData, fetchAnsprechpartner, cache }}>
      {props.children}
    </CacheContext.Provider>
  );
}

const withConsumer = (WrappedComponent) => (props) =>
  (
    <CacheContext.Consumer>
      {(value) => (
        <WrappedComponent
          {...props}
          fetchData={value.fetchData}
          fetchAnsprechpartner={value.fetchAnsprechpartner}
          cache={value.cache}
        />
      )}
    </CacheContext.Consumer>
  );

export { CacheContext, CacheProvider, withConsumer };

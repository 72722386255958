import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import * as css from 'classnames'

import useOutsideClick from '../../utils/useOutsideClick'

function MenuLevel( {content, level, toggleSubMenu, toggleMobMenu} ) {
  let contentArr = Object.values(content)
  return contentArr.map((item, i) => {

    if ( ! item.showInMenu) return null
    return (
      <div className={(level === 0) 
                ? 'header-mob--menu-list-item' 
                : `header-mob--menu-submenu-item mob-level-${level}`
              }
            key={i} 
            onClick={toggleSubMenu}>
        <Link to={{ 
          pathname: `/${item.id}`, 
          state: { preFilter: '' } }} 
          onClick={() => {
            if (item.filterInMenu === undefined && item.children === undefined) {
              toggleMobMenu()
            }
          }}>
            {item.title}
        </Link>
        
        <div className='header-mob--menu-submenu is-closed childSubMenus'>
          {item.type === 'list' && item.filterInMenu &&
            <>
              {item.filterInMenu.map((filterItem, j) => {
                  return (
                    <div className={`header-mob--menu-submenu-item mob-level-${level}`} key={j} onClick={toggleSubMenu}>
                      <Link to={{ pathname: `/${item.id}`, state: { preFilter: filterItem }}} 
                        onClick={() => {
                          toggleMobMenu()
                        }}>
                        {filterItem}
                      </Link>
                    </div>
                  )
              })}
            </>
          }
          {item.children && 
            <MenuLevel content={item.children} level={level + 1} toggleMobMenu={toggleMobMenu}/>
          }
        </div>
      </div>
    )
  })
}

function MobileMenu( {content, show, toggleMobMenu} ) {
  const mobMenu = useRef()

  const toggleSubMenu = (event) => {
    const item = event.target.parentNode
    const submenu = item.querySelector('.header-mob--menu-submenu')
    
    if (submenu && submenu.scrollHeight) {
      if (submenu.classList.contains('is-closed')) {
        submenu.classList.remove('is-closed')
      } else {
        submenu.classList.add('is-closed')
      }
    }
    event.stopPropagation();
  }
  
  useOutsideClick(mobMenu, () => {
    if (show) toggleMobMenu()
  })

  if (content) {
    let menuHTMLItems = <MenuLevel content={content} level={0} toggleSubMenu={toggleSubMenu} toggleMobMenu={toggleMobMenu}/>
    return (
      <div className={css('header-mob--menu-list', {'is-open': show})} ref={mobMenu}>
        <div className='header-mob--menu-list-inner'>
          {menuHTMLItems}
        </div>
      </div>
    )
  }
  return null
}

export default MobileMenu

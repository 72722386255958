import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function MenuLevel({ content, level }) {
  let contentArr = Object.values(content);
  const location = useLocation();

  return contentArr.map((item, i) => {
    if (!item.showInMenu) return null;

    let menuItemClasses = "";
    if (level === 0) {
      menuItemClasses = "header--menu-list-item";
      if (
        location.pathname.substring(1).split("/")[0] === item.id.split("/")[0]
      ) {
        menuItemClasses += " is-active";
      }
    } else {
      menuItemClasses = `header--menu-submenu-item level-${level}`;
      menuItemClasses +=
        location.pathname.substring(1) === item.id ? " is-active" : "";
    }

    return (
      <div className={menuItemClasses} key={i}>
        <Link to={{ pathname: `/${item.id}`, state: { preFilter: "" } }}>
          {item.title}
        </Link>
        {((item.type === "list" && item.filterInMenu) || item.children) && (
          <div className="header--menu-submenu">
            {item.type === "list" && item.filterInMenu && (
              <>
                {item.filterInMenu.sort().map((filterItem, j) => {
                  return filterItem !== "" ? (
                    <div
                      className={`header--menu-submenu-item level-${level}`}
                      key={j}
                    >
                      <Link
                        to={{
                          pathname: `/${item.id}`,
                          state: { preFilter: filterItem },
                        }}
                      >
                        {filterItem}
                      </Link>
                    </div>
                  ) : null;
                })}
              </>
            )}
            {item.children && (
              <MenuLevel content={item.children} level={level + 1} />
            )}
          </div>
        )}
      </div>
    );
  });
}

function Menu(props) {
  let [content] = useState(props.content);

  if (content) {
    let menuHTMLItems = <MenuLevel content={content} level={0} />;
    return (
      <div className="header--menu">
        <div className="header--menu-list">{menuHTMLItems}</div>
      </div>
    );
  }
  return null;
}

export default Menu;

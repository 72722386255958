import React from "react"
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner'

const CookieBannerGDPR = ( {onAcceptStatistics,  onAcceptMarketing } ) => {

    const handleAcceptMarketing = () => {
        onAcceptMarketing();
    }

    const handleAcceptStatistics = () => {
        onAcceptStatistics();
    }
    
      
    return (          
        <CookieBanner
            message="Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten Ihre personenbezogenen Daten (z.B. IP-Adresse), um z.B. Inhalte und Anzeigen zu personalisieren, Medien von Drittanbietern einzubinden oder Zugriffe auf unsere Website zu analysieren. Die Datenverarbeitung kann auch erst in Folge gesetzter Cookies stattfinden. Wir geben diese Daten an Dritte weiter, die wir in den Privatsphäre-Einstellungen benennen. Die Datenverarbeitung kann mit Ihrer Einwilligung oder auf Basis eines berechtigten Interesses erfolgen, dem Sie in den Privatsphäre-Einstellungen widersprechen können. Sie haben das Recht, Ihre Einwilligung nicht zu erteilen und Ihre Einwilligung zu einem späteren Zeitpunkt zu ändern oder zu widerrufen. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer Datenschutzerklärung.Sie sind unter 16 Jahre alt? Dann können Sie nicht in optionale Services einwilligen. Bitten Sie Ihre Eltern oder Erziehungsberechtigten, mit Ihnen in diese Services einzuwilligen."                
            policyLink="/rechtliches/datenschutzerklaerung"
            className="cookieBanner"
            privacyPolicyLinkText="Datenschutzerklärung"
            acceptSelectionButtonText="Auswahl akzeptieren"
            statisticsOptionText="Statistiken"
            acceptAllButtonText="Alle akzeptieren"
            necessaryOptionText="Notwendig"
            showAcceptSelectionButton={true}
            onAcceptStatistics = {handleAcceptStatistics}
            onAcceptMarketing = {handleAcceptMarketing}
            showMarketingOption={true}
            showPreferencesOption={false}
            styles={{dialog:{}, container: {}, message: {}, button:{}, buttonWrapper: {}, checkbox: {}, optionLabel: {}, optionWrapper:{}, selectPane:{}, policy:{}}}
        />        
    )
}
export default CookieBannerGDPR
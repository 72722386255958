import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { withConsumer } from "../../helpers/CacheContext";

import Search from "./Search";
import Menu from "./Menu";
import MobileMenu from "./MobileMenu";

const Header = ({ fetchData, location }) => {
  const [content, setContent] = useState();
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [showMob, setShowMob] = useState(false);
  const search = useRef();

  const toggleMobMenu = () => {
    setShowMob((value) => !value);
  };

  const toggleSearch = () => {
    search.current.focus();
    setSearchBarOpen((value) => !value);
  };

  useEffect(() => {
    fetchData("submenus").then((data) => {
      setContent(data);

      let promises = [];
      Object.values(data).forEach((element) => {
        if (element.type === "list") {
          promises.push(fetchData(element.id));
        }
      });

      Promise.all(promises).then((values) => {
        for (let i = 0; i < values.length; i++) {
          let kategorien = new Set();
          const element = values[i];

          if (element.items) {
            let items = Object.values(element.items);
            for (let j = 0; j < items.length; j++) {
              const item = items[j];
              if (item[element.filterVariable] !== null) {
                kategorien.add(item[element.filterVariable]);
              }
            }

            data[values[i].path].filterInMenu = [...kategorien];
          }
        }

        // copy object so that child components get a new prop object
        let dataCopy = Object.assign({}, data);
        setContent(dataCopy);
      });
    });
  }, [fetchData]);

  return (
    <div className="header--wrap hidden-print">
      <Search
        searchRef={search}
        searchBarOpen={searchBarOpen}
        setSearchBarOpen={setSearchBarOpen}
      />

      <div className="header--inner">
        <div className="header--logo">
          <Link to="/" className="header--logo-link">
            <svg className="inline-svg-icon" fill="none">
              <use xlinkHref="#logo"></use>
            </svg>
          </Link>
        </div>

        {content !== null && content !== undefined && (
          <Menu content={content} />
        )}

        <div className="header--icons-list">
          <a
            className="header--icons-list-item"
            href="//intern.soal.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="inline-svg-icon"
              style={{ enableBackground: "new 0 0 48 48" }}
              xmlSpace="preserve"
            >
              <use href="#soal_login"></use>
            </svg>
          </a>
          <Link to="/kontakt" className="header--icons-list-item">
            <svg
              className="inline-svg-icon"
              style={{ enableBackground: "new 0 0 48 48" }}
              xmlSpace="preserve"
            >
              <use href="#soal_contact"></use>
            </svg>
          </Link>
          <a
            href="https://www.instagram.com/soal.wohlfahrtsverband/"
            className="header--icons-list-item"
            target="_blank"
          >
            <svg
              className="inline-svg-icon"
              style={{ enableBackground: "new 0 0 48 48" }}
              xmlSpace="preserve"
            >
              <use href="#soal_instagram"></use>
            </svg>
          </a>
          <div className="header--icons-list-item" onClick={toggleSearch}>
            <svg
              className="inline-svg-icon"
              style={{ enableBackground: "new 0 0 48 48" }}
              xmlSpace="preserve"
            >
              <use className="stroke" href="#soal_search"></use>
            </svg>
          </div>
          <div className="header-mob--menu">
            <div className="header-mob--menu-icon" onClick={toggleMobMenu}>
              <svg className="inline-svg-icon" fill="none">
                <use href="#burger"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <MobileMenu
        content={content}
        show={showMob}
        toggleMobMenu={toggleMobMenu}
      />
    </div>
  );
};

export default withConsumer(Header);

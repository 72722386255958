import React from "react";
import * as css from "classnames";
import { withRouter } from "react-router";

function Search({ searchRef, searchBarOpen, setSearchBarOpen, history }) {
  const getSearch = () => {
    history.push(encodeURI(`/suche/${searchRef.current.value}`));
    setSearchBarOpen(false);
    setTimeout(() => {
      searchRef.current.value = "";
    }, 250);
  };

  const searchEnterHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      getSearch();
    }
  };

  return (
    <div className={css("header--search-bar", { "is-hidden": !searchBarOpen })}>
      <div className="header--search-bar-inner">
        <div
          className="header--search-bar-close"
          onClick={() => setSearchBarOpen(false)}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 36 36"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
          >
            <use xlinkHref="#_Image1" x="0" y="0" />
            <defs>
              <image
                id="_Image1"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA2ElEQVRYhc3Y0Q2DMAwE0KgzZA72/2EI5mCI60fdqo2oiO3zKf4lunsEgVBaswHQAewAtiYaAJt19vFCB3DgNacCZZjTOo8PasBAgRow+EHZll1NCeoP5j373QIqarpLgXJ3VKLC2RWodCYTRctiBNF3OxNIx2SCyzCRgnKMp0iGcaB0mEmUFhNASX5jZlFhzIONlc9Sj8yBqUfdYLSv/QRG92H0FJWjIgVlqEwwHcUIpKGYd5fOom91JrMCE86uxLg7FBgXCgseNqx1HGML1jmw+lqwxJHeE83mDwlBkoPNAAAAAElFTkSuQmCC"
              />
            </defs>
          </svg>
        </div>
        <input
          className="header--search-bar-input"
          placeholder="Suchbegriffe eingeben..."
          ref={searchRef}
          onKeyUp={searchEnterHandler}
        />
        <div className="header--search-bar-button" onClick={getSearch}>
          Suchen
        </div>
      </div>
    </div>
  );
}

export default withRouter(Search);

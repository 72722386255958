import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { withConsumer } from "../helpers/CacheContext";

import logoWhite from "../assets/soal-logo_white.svg";
import soalMap from "../assets/soalde-karte.jpg";

const Footer = ({ fetchData, cookieState }) => {
  const [siteMap, setSiteMap] = useState();
  const [content, setContent] = useState();

  useEffect(() => {
    let promisesRoot = [];

    promisesRoot.push(fetchData("submenus"));
    promisesRoot.push(fetchData("home"));

    Promise.all(promisesRoot).then((data) => {
      setSiteMap(data[0]);
      setContent(data[1].footer);

      let promises = [];
      Object.values(data[0]).forEach((element) => {
        if (element.type === "list") {
          promises.push(fetchData(element.id));
        }
      });

      Promise.all(promises).then((values) => {
        for (let i = 0; i < values.length; i++) {
          let kategorien = new Set();
          const element = values[i];

          if (element.items) {
            let items = Object.values(element.items);
            for (let j = 0; j < items.length; j++) {
              const item = items[j];
              if (item[element.filterVariable] !== null) {
                kategorien.add(item[element.filterVariable]);
              }
            }

            data[0][values[i].path].filterInMenu = [...kategorien];
          }
        }

        // copy object so that child components get a new prop object
        let dataCopy = Object.assign({}, data[0]);
        setSiteMap(dataCopy);
      });
    });
  }, [fetchData]);

  return (
    <div className="footer-wrap hidden-print">
      <div className="footer-block">
        <div className="footer-block-big footer-block--img">
          {cookieState && (
            <iframe
              title="gmaps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.3461023051136!2d9.941804716342618!3d53.55158888002253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18f67f543137d%3A0xb94da471d9f56a5a!2sSOAL%20e.V.%2C%20Alternativer%20Wohlfahrtsverband%2C%20Sozial%20%26%20Alternativ!5e0!3m2!1sen!2sde!4v1605705869982!5m2!1sen!2sde"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          )}
          {!cookieState && (
            <img
              className="footer-block--soalmap"
              src={soalMap}
              alt="soal map"
            />
          )}
        </div>
        <div className="footer-block-small">
          <div className="footer-block--content is-bg-magenta">
            <div className="footer-block--content-headline">
              <img
                className="footer-block--logo"
                src={logoWhite}
                alt="white logo"
              />
            </div>
            <div
              className="footer-block--content-text"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </div>
        </div>
      </div>
      <div className="footer-block">
        <div className="footer-block--content is-full">
          <div className="footer-block--nav">
            {siteMap &&
              Object.values(siteMap).map((item, i) => {
                if (!item.showInSitemap) return null;
                return (
                  <div className="footer-block--nav-block" key={i}>
                    <div className="footer-block--nav-headline">
                      <Link to={"/" + item.id}>{item.title}</Link>
                    </div>

                    {item.type === "list" &&
                      item.filterInMenu &&
                      item.filterInMenu.map((filterItem, j) => {
                        return (
                          <div className="footer-block--nav-link" key={j}>
                            <Link
                              to={{
                                pathname: "/" + item.id,
                                state: { preFilter: filterItem },
                              }}
                            >
                              {filterItem}
                            </Link>
                          </div>
                        );
                      })}

                    {item.children &&
                      Object.values(item.children).map((subitem, j) => {
                        if (!subitem.showInSitemap) return null;
                        return (
                          <div className="footer-block--nav-link" key={j}>
                            <Link to={"/" + subitem.id}>{subitem.title}</Link>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withConsumer(Footer);
